.card-glass {
  background-color: rgba(255, 255, 255, .5);
  backdrop-filter: blur(.25rem);
  border-radius: 0.75rem;

  border-color: transparent;
  &.card-start {
    border-right-color: $border-color;
  }
  &.card-middle {
    border-right-color: $border-color;
    border-left-color: $border-color;
  }
  &.card-end {
    border-left-color: $border-color;
  }
}
.card-glass-dark {
  background-color: rgba(25, 25, 25, .5);
  backdrop-filter: blur(.25rem);
  color: white;
  box-shadow: 0 0 10px -5px black;
  border: none !important;
}
.card-glass-darkblue {
  @extend .card-glass-dark;
  background-color: rgba(6, 32, 52, .5);
}

.card-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.card-middle {
  border-radius: 0;
}
.card-end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mapcontainer {
  overflow: hidden;
  margin-left: -50px;
  height: 100% !important;

  .leaflet-control-attribution {
    background-color: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.5);
    a {
      color: rgba(255, 255, 255, 0.5);
      svg {
        display: none !important;
      }
    }
  }

  .leaflet-layer {
    &.weather {
      filter: contrast(150%) saturate(150%) hue-rotate(160deg) brightness(150%);
    }
  }
}

.sun-color {
  color: #FF5000;
}
.fad-sun-primary {
  --fa-primary-color: #FF5000;
  --fa-primary-opacity: 1;
}
.fad-sun-secondary {
  --fa-secondary-color: #FF5000;
  --fa-secondary-opacity: 1;
}
.fad-rain-secondary {
  --fa-secondary-color: #0077ff;
  --fa-secondary-opacity: 1;
}
.fad-thunder-secondary {
  --fa-secondary-color: #ffdd00;
  --fa-secondary-opacity: 1;
}
