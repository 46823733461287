@import "~bootstrap/scss/bootstrap";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "~react-loading-skeleton/dist/skeleton.css";

@import "~leaflet/dist/leaflet.css";

@import "carousel";
@import "christmas";
@import "development";
@import "energy-flow-card";
@import "energy-handling-chart";
@import "gridsense";
@import "images";
@import "layout";
@import "live-indicator";
@import "login";
@import "logo";
@import "modal";
@import "planning";
@import "scrollbar";
@import "sentry";
@import "sidebar";
@import "tabbar";
@import "table";
@import "title";
@import "transition";
@import "weather";

body {
  overflow-y: hidden;
}

.card-comment {
  margin-bottom: 15px;
}

.loading-row {
  background-color: transparent !important;
}

.clickable {
  cursor: pointer;
  webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.detail-header-cell {
  text-align: right;
  width: 250px;
}

.detail-header-cell-sm {
  text-align: right;
  width: 250px;
}

.page-container {
  margin-bottom: 200px;
  margin-left: 250px;
  padding-top: 80px;
  padding-left: 45px;
  padding-right: 45px;
  @media print {
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 45px;
  }
}

/* Redesign */

html {
  height: 100%;
  width: 100%;
}

body {
  position: relative; // Required for tooltips to function correctly.
}

.background {
  background-color: #4c2c00;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  .background-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.navbar-only {
  box-shadow: none;
  background-color: #FFFFFF !important;
  border-bottom: #E3EBF6 1px solid;
}

.badge-pill {
  padding: .3rem .6rem;
}

.card {
  border: 1px solid #E3EBF6;
}
.card-header {
  border-bottom: 1px solid #E3EBF6;
  background-color: #F9FBFD;
}
.card-footer {
  border-top: 1px solid #E3EBF6;
  background-color: #F9FBFD;
}

.dropdown-menu {
  border: 1px solid #E3EBF6;
}
.dropdown-menu-navbar {
  margin-top: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-control {
  border-color: #E3EBF6;
}

hr {
  border-color: #E3EBF6;
}

.card-qrcode {
  @media print {
    display: block !important;
  }
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.card-arrow-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 20px 40px 0;
  border-color:
          transparent #fff transparent
          transparent;
  float: left;
}
.card-arrow-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 40px 20px;
  border-color:
          transparent transparent
          transparent #fff;
  float: right;
}
.left-border-0 {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.right-border-0 {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn {
  @media print {
    display: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.btn {
  @media print {
    display: none;
  }
}

.no-link-style {
  color: #212529;
  text-decoration: none;
  &:hover {
    color: #212529;
    text-decoration: none;
  }
}

@font-face {
  font-family: Brush Tip Terrence;
  src: url(../fonts/brushtipterrence.woff)
}
.slogan {
  font-family: "Brush Tip Terrence",serif;
}

.april-fools {
  color: white;
  font-size: 9rem;
  text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
  //font-family: "Comic Sans MS", "Comic Sans", cursive;
  font-weight: 800;
}

.monospace {
  font-family: monospace;
}

.moving-content-standard {
  animation: float-standard 180s steps(1) infinite;
}

.moving-content-high-scaling {
  animation: float-high-scaling 180s steps(1) infinite;
}

@keyframes float-standard {
  0% { transform: translate(0, 0); }
  10% { transform: translate(1px, 1px); }
  20% { transform: translate(2px, 2px); }
  30% { transform: translate(3px, 3px); }
  40% { transform: translate(4px, 4px); }
  50% { transform: translate(5px, 5px); }
  60% { transform: translate(4px, 4px); }
  70% { transform: translate(3px, 3px); }
  80% { transform: translate(2px, 2px); }
  90% { transform: translate(1px, 1px); }
  100% { transform: translate(0, 0); }
}

@keyframes float-high-scaling {
  0% { transform: translate(0, 0); }
  10% { transform: translate(0.5px, 0.5px); }
  20% { transform: translate(1px, 1px); }
  30% { transform: translate(1.5px, 1.5px); }
  40% { transform: translate(2px, 2px); }
  50% { transform: translate(2.5px, 2.5px); }
  60% { transform: translate(2px, 2px); }
  70% { transform: translate(1.5px, 1.5px); }
  80% { transform: translate(1px, 1px); }
  90% { transform: translate(0.5px, 0.5px); }
  100% { transform: translate(0, 0); }
}
