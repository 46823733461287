@mixin row-cols($value) {
  .row-cols-#{$value} > * {
    flex: 0 0 (100% / $value);
    max-width: (100% / $value);
  }
}
@mixin row-cols-bp($breakpoint, $value) {
  .row-cols-#{$breakpoint}-#{$value} > * {
    flex: 0 0 (100% / $value);
    max-width: (100% / $value);
  }
}

@include row-cols(7);
@include row-cols(8);
@include row-cols(9);
@include row-cols(10);
@media (min-width: 576px) {
  $breakpoint: "sm";
  @include row-cols-bp($breakpoint, 7);
  @include row-cols-bp($breakpoint, 8);
  @include row-cols-bp($breakpoint, 9);
  @include row-cols-bp($breakpoint, 10);
}
@media (min-width: 768px) {
  $breakpoint: "md";
  @include row-cols-bp($breakpoint, 7);
  @include row-cols-bp($breakpoint, 8);
  @include row-cols-bp($breakpoint, 9);
  @include row-cols-bp($breakpoint, 10);
}
@media (min-width: 992px) {
  $breakpoint: "lg";
  @include row-cols-bp($breakpoint, 7);
  @include row-cols-bp($breakpoint, 8);
  @include row-cols-bp($breakpoint, 9);
  @include row-cols-bp($breakpoint, 10);
}
@media (min-width: 1200px) {
  $breakpoint: "xl";
  @include row-cols-bp($breakpoint, 7);
  @include row-cols-bp($breakpoint, 8);
  @include row-cols-bp($breakpoint, 9);
  @include row-cols-bp($breakpoint, 10);
}

.d-grid {
  display: grid;
  &.equal-columns {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}
