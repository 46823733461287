$animationDuration: 500ms;

/* initial-load-background */
.initial-load-background-enter {
  opacity: 0;
}
.initial-load-background-enter-active {
  opacity: 1;
  transition: opacity $animationDuration;
}
.initial-load-background-exit {
  opacity: 1;
}
.initial-load-background-exit-active {
  opacity: 0;
  transition: opacity $animationDuration;
}

/* initial-load-logo */
.initial-load-logo,
.initial-load-logo-enter {
  opacity: 0;
}
.initial-load-logo-enter-active {
  opacity: 1;
  transition: opacity $animationDuration;
}
.initial-load-logo-entered,
.initial-load-logo-exit {
  opacity: 1;
}
.initial-load-logo-exit-active {
  opacity: 0;
  transition: opacity $animationDuration;
}

.fade-enter div {
  opacity: 0;
  transform: translateY(-100%);
}
.fade-enter-active div {
  opacity: 1;
  transform: translateY(0%);
}
.fade-exit div {
  opacity: 1;
  transform: translateY(0%);
}
.fade-exit-active div {
  opacity: 0;
  transform: translateY(100%);
}
.fade-enter-active div,
.fade-exit-active div {
  transition: opacity 500ms, transform 500ms;
}
