.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  padding: 20px;
  gap: 20px;
  width: 100%;
  height: 100vh;

  .grid-item {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    color: black;
    font-size: 30px;
    text-align: center;
    border-radius: 20px;
    overflow: hidden;
    grid-column: span 6;
    grid-row: span 6;
  }

  .grid-item-long {
    grid-row: span 12;
  }

  .grid-item-wide {
    grid-column: span 7;
  }

  .grid-item-narrow {
    grid-column: span 5;
  }
}

