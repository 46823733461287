.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  max-height: 100vh;
  height: 100vh;
  width: 100%;
  max-width: 250px;
  z-index: 1031;

  padding: 0.75rem 2rem 1rem;

  background-image: linear-gradient(to bottom right, rgba(230, 72, 0, .9), rgba(173, 128, 0, .9)), url(./../img/solar-banner.jpg);
  background-repeat: no-repeat,no-repeat;
  background-position: center center,center center;
  background-size: cover,cover;
  background-origin: border-box;
  border-color: transparent;

  @media print {
    display: none;
  }

  .nav {
    flex: 1 1 auto;
    flex-direction: column;
  }
}

.sidebar-logo {
  display: inline-block;
  width: 100%;
  svg {
    width: 100%;
    max-height: 3rem;
    margin-top: 20px;
    margin-bottom: 20px;

    .sidebar-logo-path {
      fill: white;
      opacity: 1;
      transition: opacity .25s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.sidebar .nav-link {
  color: rgba(255, 255, 255, .6);
  padding-left: 0;
  padding-right: 0;
  transition: color .15s ease;
  &.active {
    background-color: transparent;
    font-weight: 600;
  }
  &:hover {
    color: rgba(255, 255, 255, .8);
  }
}

.sidebar .nav-link .badge-primary {
  background-color: rgba(255, 255, 255, .6);
  color: rgba(0, 0, 0, .6);
}
.sidebar .nav-link:active .badge-primary,
.sidebar .nav-link:hover .badge-primary,
.sidebar .nav-link:focus .badge-primary {
  background-color: rgba(255, 255, 255, .8);
  color: rgba(0, 0, 0, .8);
}

.sidebar-divider {
  height: 33px;
  hr {
    border-color: rgba(255,255,255,.2);
  }
}
