$primary: #FF5000;

$nav-row-border-color: #e3ebf6;
$nav-row-item-color: #95aac9;
$nav-row-item-color-active: #12263f;
$nav-row-item-color-hover: #6e84a3;
$nav-row-item-color-disabled: rgba(31, 35, 38, .5);
$nav-row-item-border-color-hover: #e3ebf6;
$nav-row-item-border-color-active: $primary;

.nav-pills .nav-item a,
.nav-pills .nav-item .active {
  color: $nav-row-item-color-active;
}

.nav-row {
  @extend .nav-pills;
  border-top: 1px $nav-row-border-color solid;
  border-bottom: 1px $nav-row-border-color solid;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .nav-item {
    a {
      color: $nav-row-item-color;
      line-height: 2.25rem;
      height: 3.5rem;
    }
    a:disabled, a.disabled {
      color: $nav-row-item-color-disabled;
      margin-bottom: 3px;
    }
    a:hover {
      color: $nav-row-item-color-hover;
      border-bottom: 3px $nav-row-item-border-color-hover solid;
      margin-bottom: 0;
    }
    a.active {
      color: $nav-row-item-color-active;
      border-bottom: 3px $nav-row-item-border-color-active solid;
      margin-bottom: 0;
    }
  }
}
