::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25) !important;
}

.white-scrollbar {
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 1) !important;
  }
}

.wide-scrollbar {
  &::-webkit-scrollbar {
    width: 15px !important;
  }
}
