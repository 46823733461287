.image-box {
  &:before {
    content: "";
    float: left;
    padding-top: 100%;
  }

  .image-box-overlay, .image-box-overlay-always {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    //transition: opacity 0.15s;
    padding: 15px;
  }
  .image-box-overlay {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .5);
    color: white;
    opacity: 0;
  }
  img {
    content-visibility: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover .image-box-overlay {
    opacity: 1;
  }
}

.images-scrolling-banner {
  width: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
  color: white;
}
