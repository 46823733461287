.modal-content {
  border-color: #E3EBF6;
  .modal-header {
    border-color: #E3EBF6;
  }
  .modal-footer {
    border-color: #E3EBF6;
  }
}

.modal-fullscreen {
  height: 100%;
  margin: 0;
  max-width: 100% !important;
  width: 100% !important;

  .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
  }
}

.modal-fullscreen-dark {
  @extend .modal-fullscreen;
  .modal-content {
    background-color: #303030;
  }
  .modal-footer {
    border-color: #434343;
  }
}

.modal-image {
  @extend .modal-fullscreen-dark;
  .modal-content {
    background-color: rgba(0, 0, 0, .8);
    .modal-body {
      padding: 0;
    }
    .modal-footer {
      background-color: rgba(0, 0, 0, .5);
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
  .modal-image-close {
    color: white;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2000;
  }
}
