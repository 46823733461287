.planning-item-column {
  .planning-item {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: solid 1px $border-color;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
